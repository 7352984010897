import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Message, Form }    from 'semantic-ui-react';
import { useHistory }       from 'react-router-dom';

import Fixed                        from 'components/Fixed';
import SortableTable                from 'components/SortableTable';
import useApiResource               from 'hooks/useApiResource';
import useApiFetcher                from 'hooks/useApiFetcher';
import VersionSearchResultResource  from 'resources/admin/VersionSearchResultResource';
import VersionRollbackResource      from 'resources/admin/VersionRollbackResource';

import formattedDate  from 'utils/formattedDate';
import Changes from 'containers/Versions/Changes';

import './style.css';

const headerLabels = {
  item_id:            'Record Id',
  item_type:          'Record Type',
  event:              'Action',
  change:             'Changes',
  created_at:         'Occurred At',
  transaction_id:     'Transaction Id'
};

const orderedColumns = [
  'item_id',
  'item_type',
  'event',
  'change',
  'created_at',
  'transaction_id'
];

const changesIndex = 3;
const dateTimeColumnIndices = [4];

function cellRenderer(_record, value, columnIndex) {
  if(columnIndex === changesIndex) {
    return value && <Changes attributes={ value } />;

  } else if(dateTimeColumnIndices.includes(columnIndex)) {
    return value && formattedDate(value);
  }

  return value;
}

function getTableData(records) {
  const result = { headers: [], records: [] };

  if(!records || records.length === 0) {
    return result;
  }

  result.headers  = orderedColumns.map(key => headerLabels[key]);
  result.records  = records.map(rec => orderedColumns.map(key => rec[key]));

  return result;
}
// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const DetailView = ({ match }) => {
  const [saving, setSaving]   = useState(false);
  const history               = useHistory();
  const { id }                = match.params;
  const version               = useApiResource(VersionSearchResultResource.detailShape(), { id });
  const rollback              = useApiFetcher(VersionRollbackResource.singletonCreateShape());
  const { related_items }     = version;

  const { headers, records }  = getTableData(related_items);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setSaving(true);
    await rollback({ id }, { transaction_id: id });
    history.replace('/admin/versions/rollback/success');
    setSaving(false);
  };

  return (
    <Form onSubmit={ handleSubmit }>
      <Message info>
        <Message.Header>
          You are about to rollback the following changes.
        </Message.Header>

        <p>Please review the changes below.</p>
      </Message>

      <SortableTable  tableId='versions'
                      className='versions-detail'
                      headerLabels={ headers }
                      records={ records }
                      cellRenderer={ cellRenderer } />

      <Fixed>
        <Form.Button  loading={ saving }
                      type='submit'
                      primary>Rollback</Form.Button>
      </Fixed>
    </Form>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

DetailView.defaultProps  = {};
DetailView.propTypes     = {
  match: PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default DetailView;
